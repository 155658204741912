<template>
  <div class="flex flex-col p-5">
    <div class="flex items-center">
      <div class="flex mx-2">
        <Avatar :image="contact.avatar" shape="circle" size="large" />
      </div>
      <div class="contact-info">
        <div class="name">{{ contact.name }}</div>
        <div class="title">{{ contact.title }}</div>
      </div>
    </div>
    <div class="bottom-section">
      <div class="flex flex-col">
        <a
          class="call-btn col mb-2 text-center flex justify-center items-center"
          :href="'tel:' + contact.phone"
        >
          <font-awesome-icon :icon="['fas', 'phone']" class="mr-1 icon" fixed-width />
          Call Me
        </a>
        <a
          class="email-btn col text-center flex justify-center items-center"
          :href="'mailto:' + contact.email"
        >
          <font-awesome-icon icon="paper-plane" class="mr-1 icon" fixed-width />
          Email
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'primevue/avatar'

export default {
  name: 'ContactCard',
  props: {
    contact: {
      type: Object,
      required: true
    }
  },
  components: {
    Avatar
  }
}
</script>

<style scoped>
.name {
  font-size: 14px;
  font-weight: bold;
}

.title {
  font-size: 12px;
  font-weight: lighter;
  color: #979797;
}

.bottom-section {
  margin-top: 20px;
  font-size: 14px;
}

.call-btn,
.email-btn {
  width: 240px;
  padding: 20px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  background-color: #e6f0fc;
  color: black;
  margin-bottom: 10px;

  .icon {
    height: 16px;
    width: 16px;
  }

  &:hover {
    background-color: #ddecff;
  }

  &:active {
    background-color: #cee4ff;
  }
}
</style>
